import { useEffect, useRef, useState } from "react";
import { FAQ_q } from "./FAQ";

import "../../../Styles/faq.css"
import Icon from "../Icon";

interface Props {
    data: FAQ_q
}

function FAQRow({data}: Props) {

    let [isOpen, setOpen] = useState(false)
    let [height, setHeight] = useState<number |undefined>(0)

    const ref = useRef<HTMLDivElement>(null);
 
    useEffect(() => {
        console.log(ref.current?.getBoundingClientRect().height)
      if (isOpen) setHeight((ref.current?.getBoundingClientRect().height || 100));
      else setHeight(0);
    }, [isOpen]);

    return ( 
        <div className="faqRow">
            <div className="title row" onClick={() => {setOpen(!isOpen)}}>
                <h2>{data.title}</h2>
                <Icon iconName={isOpen ? "caret-up-outline" : "caret-down-outline"} size={"2vw"}/>
            </div>
            <div className="dropdown" style={{height: height}}>
                <div className="content" ref={ref}>
                <p>{data.desc}</p>
                </div>
            </div>
        </div>
     );
}

export default FAQRow;