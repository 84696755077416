import Joi from "joi";

/*
  Requests
*/

const defaultUrl = "http://localhost:8080/separateSite"

const sendReq = (type: "GET" | "POST", url: string, endpoint: string, body: Object, errMsgSetter?: Function) => {
    return fetch(url + endpoint, {
      method: type,
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(async (res) => {
        let temp = await res.json();
        temp.status = res.status;
        return temp;
      })
      .catch((err) => {
        console.log(err);
        if (errMsgSetter) {errMsgSetter("general-err")}
      });
  }
  
  export const sendGetReq = async (endpoint: string, body: Object, errMsgSetter?: Function, url: string = defaultUrl) => {
    return await sendReq("GET", url, endpoint, body, errMsgSetter);
  };
  
  export const sendPostReq = async (endpoint: string, body: Object, errMsgSetter?: Function, url: string = defaultUrl) => {
    return await sendReq("POST", url, endpoint, body, errMsgSetter);
  };


/*
  Miscelaneous
*/
export const onChange = (data: Object, setData: Function, e: any) => {
  let temp = data;
  let id: keyof typeof temp = e.target.id;
  temp[id] = e.target.value;
  setData(temp);
};

export const compressObjects = (data: Object[], key: string) => {
  return data.map((o : {[key: string]: any}) => {
    return o[key]
  })
}

export const createArr = (len: number, defaultVal: any = null) => {
  return Array.apply(defaultVal, Array(len)).map(function () {return defaultVal})
}


/*
  General types
*/
export type StrIndexedObj = {[key: string]: any}

/*
  Joi schemas
*/

export const schemas = {

  teamName: Joi.string()
      .pattern(new RegExp(`^[^<>"';*\/]*$`))
      .min(3)
      .max(30)
      .required()
      .messages({
        "string.base": "Prosím zadej validní název týmu. (velká a malá písmena, číslice)",
        "string.pattern.base": "Jméno týmu nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
        "string.empty": "Prosím zadej název týmu.",
        "string.min": "Název týmu musí být minimálně 3 znaky dlouhý.",
        "string.max": "Název týmu může být maximálně 30 znaků dlouhý.",
      }),

  email: Joi.string()
    .email({ tlds: { allow: false } }) //TODO: Figure out how to allow TLDs
    .empty()
    .max(100)
    .messages({
    "string.base": "Prosím zadej validní email.",
    "string.email": "Prosím zadej validní email.",
    "string.empty": "Prosím zadej email.",
  }),

  teacherEmail: Joi.string()
    .email({ tlds: { allow: false } }) //TODO: Figure out how to allow TLDs
    .allow("")
    .max(100)
    .messages({
    "string.base": "Prosím zadej validní učitelský email.",
    "string.email": "Prosím zadej validní učitelský email.",
  }),
  

}

export const registerSchema = Joi.object({
    teamName: schemas.teamName,
    email: schemas.teamName
  })