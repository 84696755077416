import { useSpecialClassHandler } from "../../Scripts/hooks/useSpecialClassHandler";

function Info() {

    useSpecialClassHandler()

    /*const titleSeriesVars = { "--duration": "0.1", "--in-duration": "0.5s", "--out-duration": "0.5s", "--overflow": "0.5s", "--delay": "0.3s", "margin": "0vw 0.7vw" } as React.CSSProperties*/

    return (

        <div className="info column centerAlign evenSpacing">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave"><path fill="#4695d6" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave secondary fadeInBottom"><path fill="#ffffff" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

            {/*<div className="logo row elementSeriesScroll centerAlign fadeTopToBottom" style={titleSeriesVars}>
                <h1 className="stay resetMargin"><i>Š</i></h1>
                <h1 className="stay resetMargin"><i>M</i></h1>
                <h1 className="stay resetMargin">o</h1>
                <h1 className="stay resetMargin">u</h1>
                <h1 className="stay resetMargin"><i>L</i></h1>
                <h1 className="stay resetMargin">a</h1>
            </div>*/ /* TODO: I dont think we should have the ŠMouLa title here so many times :( */}

            <div style={{width: "65vw", marginTop: "15vh"}}>
                <h2 className="fadeInBottom"><i>ŠM</i>ou<i>L</i>a je jedinečná šifrovací-matematicko-logická soutěž určená pro tříčlenné týmy žáků druhého stupně základních škol a odpovídajících ročníků víceletých gymnázií.</h2>
                <div style={{height: "5vh"}}></div>
                <h2 className="fadeInBottom" style={{animationDelay: "0.2s"}}>
                    Zaměřujeme se na:<br/>
                        - rozvoj klíčových dovedností pro 21. století
                    <br/><br/>
                    Mezi hlavní cíle soutěže patří:<br/>
                        - zdokonalovat dovednost žáků hledat informace na internetu<br/>
                        - naučit žáky správně psát dotazy umělé inteligenci<br/>
                        - rozvíjet jejich mediální gramotnost<br/>
                    </h2>
                    {/* TODO: upravit placeholdery (prijde mi to nechutny????) - niki */}
            </div>

        </div>

    );
}

export default Info;