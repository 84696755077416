import { createArr } from "../../Scripts/misc";

import "../../Styles/animSeparator.scss"

function AnimSeparator() {

    const triangleAmt = 14;
    const squareAmt = 14;
    const circleAmt = 14;

    return ( 
        <div className="animSep">
            {createArr(triangleAmt).map(n => {
                    return <div className="animObj triangle"/>
                })}

                {createArr(squareAmt).map(n => {
                    return <div className="animObj square"/>
                })}
                
                {createArr(circleAmt).map(n => {
                    return <div className="animObj circle"/>
                })}
        </div>
     );
}

export default AnimSeparator;