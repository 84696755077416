import FAQRow from "./FAQRow";

export interface FAQ_q {
    title: string,
    desc: string
}

interface Props {
    questions: FAQ_q[]
}

function FAQ({questions}: Props) {

    return ( 
    <div className="faq centerAlign">

        <div>
        {questions.map(q => <FAQRow data={q}/>)}
        </div>

    </div>
     );
}

export default FAQ;