import { useEffect } from "react";
import AnimBg from "../Animated/AnimBg";
import Slideshow, { useSlideshowController } from "../General/Slideshow/Slideshow";

interface Props {
    delayMs: number
}

function Header({delayMs}: Props) {

    const controller = useSlideshowController([
        <h2><i>š</i>ifrovací</h2>,
        <h2><i>m</i>atematická</h2>,
        <h2><i>l</i>ogická</h2>
    ], "slide-bottomToTop")

    useEffect(() => {
        setTimeout(() => {setInterval(() => { controller.nextPage() }, 2500)},delayMs)
    }, [])

    return (

        <div className="header column centerAlign">

            <AnimBg />

            <div className="titleContainer">
                <h1 className="title logo centerAlign resetMargin"><i>ŠM</i><p>ou</p><i>L</i><p>a</p><br /></h1>
                {/*<h2>je teď na prázdninách</h2>

            <h3 className="contactDetails">V případě potřeby nás prosím kontaktujte na <a href="mailto:info@smoulasoutez.cz">info@smoulasoutez.cz</a> :)
            <br/><small>Telefon: <a href="tel:+420228222698">+420 228 222 698</a></small>
            </h3>*/}

                <div className="column resetMargin">
                    <div className="row centerAlign"> {/* TODO: Make slideshow witdth relative/resize container after title change*/}
                        <h2>je</h2>
                        <div className="titleSlide">
                            <Slideshow controller={controller} />
                        </div>
                        <h2>soutěž</h2>
                    </div>
                    <h2 className="resetMargin">pro druhý stupeň základní školy</h2>
                </div>

            </div>



            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave"><path fill="#4695d6" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

        </div>

    );
}

export default Header;