import { Link } from "react-router-dom";

import AnimWave from "../Components/Animated/AnimWave";
import { useSpecialClassHandler } from "../Scripts/hooks/useSpecialClassHandler";

function RegistrationPage() {

    useSpecialClassHandler()

    return ( 
        <div className="container column centerAlign">
            <div className="elementSeries absPos centerAlign fadeIn">
                <div style={{ "--duration": "2s"  } as React.CSSProperties} className="elementSeries fadeTopToBottom">
                    <h1 className="stay">Ty jsi se rozhodl se zaregistrovat?</h1>
                    <h1 className="stay">Skvělé!</h1>
                </div>
                <div className="container centerAlign column stay">
                    <h1>Registrace jsou ale bohužel ještě uzavřené</h1>
                    <h1>Zkus to prosím později</h1>
                    <Link to="/"><button style={{marginTop: "min(3vh,3vw)"}}>Zpět na hlavní stránku</button></Link>
                    <AnimWave/>
                </div>
            </div>
        </div>
     );
}

export default RegistrationPage;