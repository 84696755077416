import { useEffect, useState } from "react";
import WelcomeAnimation from "../Components/General/WelcomeAnimation";
import Header from "../Components/Homepage/Header";
import Info from "../Components/Homepage/Info";

import "../Styles/homepage.css"
import FAQPage from "../Components/Homepage/FAQPage";
import AnimSeparator from "../Components/Animated/AnimSeparator";
import Contact from "../Components/Homepage/Contact";
import Harmonogram from "../Components/Homepage/Harmonogram";

function Homepage() {

    let [contentVisible, setContentVisible] = useState(false)

    const welcomeAnimDuration = 4000

    useEffect(() => {
        setTimeout(() => {setContentVisible(true)}, welcomeAnimDuration)
    })

    return ( 
        <>
            <WelcomeAnimation/>
            <Header delayMs={welcomeAnimDuration-1000}/>
            {contentVisible && <>
                <Info/>
                <AnimSeparator/>
                <FAQPage/>
                <Contact/>
            </>}
        </>

    );
}

export default Homepage;