import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Homepage from './Pages/Homepage';

import './Styles/main.css';
import RegistrationPage from './Pages/RegistrationPagePlaceholder';
/*import RegistrationPage from './Pages/RegistrationPage';*/

import "./Styles/specialClasses.css"
import { useSpecialClassHandler } from './Scripts/hooks/useSpecialClassHandler';
import ArchivePage from './Pages/ArchivePage';

function App() {

  useSpecialClassHandler() //TODO: Do I need to call this on every page?

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        {/*<Route path="/archiv" element={<ArchivePage/>}/>*/}
        <Route path="/registrace" element={<RegistrationPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
